import React from 'react';
import Stack from "@mui/material/Stack"
import Divider from "@mui/material/Divider"

const TabContainerSkeleton = ({ children, noBorder = false }) => (
    <React.Fragment>
        <Stack
            direction="row"
            spacing={4}
            sx={{
                overflow: 'hidden',
                flexWrap: 'nowrap',
                paddingTop: 1,
                paddingBottom: 2,
            }}
        >
            {children}
        </Stack>
        {!noBorder && <Divider sx={{width: '100%'}} />}
    </React.Fragment>
)

export default TabContainerSkeleton;
