import React from 'react'
import { ListActionsBoxWrap } from '../../../../StyleComp'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Grid, Card, CardContent, Typography, Link, Icon, IconButton, Box, useTheme, useMediaQuery, FormControl, InputLabel, Select, MenuItem, Divider } from '@mui/material';
import { displayData } from '../../../../helper/dateformat';
import { formatPrice } from '../../../../helper/action';
import StarberryIcons from '../../../../icons';

const List = ({data, isMobile}) => {
  return (
    <ListActionsBoxWrap>
    {!isMobile ? (
        <TableContainer>
        <Table aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Category / Type</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Outstanding</TableCell>
                <TableCell>Status</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {data.map((row, index) => (
                <TableRow
                key={index}
                sx={{
                    backgroundColor: index % 2 === 1 ? 'background.default' : 'white',
                }}
                >
                <TableCell>
                    <Box className='table-data'>
                    <StarberryIcons iconName="customDocumentsIcon" />
                    {row.title}
                    </Box>
                </TableCell>
                <TableCell>
                    {row.category} - {row.type}
                </TableCell>
                <TableCell>
                    {displayData(row?.date)}
                </TableCell>
                <TableCell>
                    {formatPrice(row?.value)}
                </TableCell>
                <TableCell>
                    {formatPrice(row?.outstanding_amount, true)}
                </TableCell>
                <TableCell>
                    {row?.status}
                </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    ) : (
        <Grid spacing={2}>
        {data.map((row, index) => (
            <Card className="action-card-item" key={index}>
            <Grid container spacing={2} alignItems="baseline">
            <Grid item className="action-icon">
                <Typography>.</Typography>
                <StarberryIcons iconName="customDocumentsIcon" />
            </Grid>
            <Grid item xs>
                <CardContent>
                <Typography variant="h6">{row.title}</Typography>
                <Typography className="info-text" variant="body2" color="textSecondary">
                    {row.category} - {row.type}
                    <Divider />
                    {displayData(row?.date)}
                    {/* <Divider />
                    Amount: {formatPrice(row?.value)} */}
                    {/* <Divider />
                    Outstanding: {formatPrice(row?.outstanding_amount, true)}
                    <Divider />
                    Status: {row?.status} */}
                </Typography>
                <Typography className="info-text" variant="body2" color="textSecondary">
                    {/* {row.category} - {row.type}
                    <Divider />
                    {displayData(row?.date)}
                    <Divider /> */}
                    Amount: {formatPrice(row?.value)}
                    <Divider />
                    Outstanding: {formatPrice(row?.outstanding_amount, true)}
                    <Divider />
                    Status: {row?.status}
                </Typography>
                {/* <Typography variant="body2" color="textSecondary">{displayData(row?.date)}</Typography> */}
                {/* <Typography variant="body2" color="textSecondary">Amount: {displayData(row?.value)}</Typography>
                <Typography variant="body2" color="textSecondary">Outstanding: {displayData(row?.outstanding_amount)}</Typography>
                <Typography variant="body2" color="textSecondary">Status: {row?.status}</Typography> */}
                </CardContent>
            </Grid>
            </Grid>
        </Card>
        ))}
        </Grid>
    )}
    </ListActionsBoxWrap>
  )
}

export default List