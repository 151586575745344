import React, { useState } from "react"
import { get, isEmpty } from "lodash"
import { useParams, useLocation } from '@reach/router';
import NoActions from "../../../../progress/NoActions"
// import Actions from '../../../../actions';
import Payments from "../../../../payments"
import Tabs from "../../../../tabs"
import SingleProperty from "../../../../property/singleProperty"
import _properties from "../../../../../../sample/properties.yaml"
import TabContainerSkeleton from "../../../../../../StarberryComponentsMui/components/Skeleton/TabContainerSkeleton"
import TabSkeleton from "../../../../../../StarberryComponentsMui/components/Skeleton/TabSkeleton"
import Stack from "@mui/material/Stack"
import AutoHeightSkeleton from "../../../../../../StarberryComponentsMui/components/Skeleton/AutoHeightSkeleton"
import BodyLargeSkeleton from "../../../../../../StarberryComponentsMui/components/Skeleton/BodyLargeSkeleton"
import { ComponentWithPagination } from "../../../../pagination"
import TransactionReport from "../transactionReport"
import { useGetUserTransactionsQuery } from "../../../../../../redux/services/property"
import { isAuthenticated } from "../../../../../../services/store/utils"
import IntroText from "../tabIntro";
import SectionTitle from "../sectionTitle";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Grid, Card, CardContent, Typography, Link, Icon, IconButton, Box, useTheme, useMediaQuery, FormControl, InputLabel, Select, MenuItem, Divider } from '@mui/material';
import StarberryIcons, { DropDownIcon } from "../../../../icons";
import { DocumentBox, FilterBox, ListActionsBoxWrap } from "../../../../StyleComp";
import { Category } from "@mui/icons-material";
import { displayData } from "../../../../helper/dateformat";
import { formatPrice } from "../../../../helper/action";
import { doRedirect, useQuery } from "../../../../utils";
import IncomeAndExpencse from "../transactionReport";
import { isBrowser } from "../../../../../Common/ggfx-client/utility";
import myaccountData from '../../../../../../../../static/data/myaccount.json';
import CircularProgressComponent from "../../../../progress/CircularProgress";
import List from "./list";

const PaymentsComponent = props => {
    return <Payments {...props} />
}
const transactionsTabData = ({ data, property, landlord, ...rest }) => {
    const tabData = []
    let count = 0
    Object.entries(data.tabs).map(([key, value]) => {
        count++
        return tabData.push({
            id: `tab${count}`,
            name: `${key}`,
            content: (
                <ComponentWithPagination
                    component={PaymentsComponent}
                    marginTop={20}
                    data={value.items}
                    totalOutstandingAmount={value.total_outstanding_amount}
                />
            ),
        })
    })

    if (landlord?.canDownloadStatement) {
        tabData.push({
            id: `tab${count + 1}`,
            name: `Income & Expenditure`,
            content: <TransactionReport {...rest} property={property} />,
        })
    }

    return tabData
}
const TabsTransactionsComp = props => {
    return <Tabs data={transactionsTabData(props)} fullWidthTabs={true} />
}

// const SinglePropertyComp = props => {
//     let offerText = ""
//     if (props.property?.offers && props.property.offers.length) {
//         offerText = `${props.property.offers.length} offers received`
//     }

//     let property = _properties[0] // Use default data if the property data is not there

//     if (props.property) {
//         property = {
//             ...props.property,
//             offerText: offerText,
//         }
//     }

//     return (
//         <>
//             <SingleProperty properties={[property]} />
//         </>
//     )
// }

const FilterComp = ({categories, data, handleChange, filter, ...rest}) => {
    return(
        <FilterBox>
        {!!data.length && data.length > 5 && (
            <>

                {/* <FormControl sx={{ m: 1, minWidth: 120 }} className="filter-select"> */}
                <div class="filter-select-wrap">
                    {/* <InputLabel id="demo-select-small">Filter Type</InputLabel> */}
                    <b>Filter:</b> 
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        label="Category"
                        defaultValue="all"
                        onChange={e => handleChange("category", e)}
                        value={filter.category}
                        IconComponent={DropDownIcon}
                    >
                        <MenuItem value={'all'}>{`All Category`}</MenuItem>
                        {categories.map(t => {
                            return <MenuItem value={t}>{t}</MenuItem>
                        })}
                    </Select>
                </div>
                {/* </FormControl> */}
            </>
        )}
    </FilterBox>

    )
}

const PaymentsContent = props => {
    console.log('props => ', props)
    // const isMobile = props.mobile
    const location = useLocation()
    const urlCatData = useQuery(location, "type")
    // const property = props.property.property_detail
    const urlParams = useParams()
    const tabName = urlParams?.tab; 
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    console.log('urlParams ]]]', urlParams)

    const authUser = isAuthenticated()

    const {
        data: propertyTransactions,
        error,
        isError,
        isLoading,
        isFetching,
    } = useGetUserTransactionsQuery(
        { },
        { skip: !authUser }
    )

    const [filter, setFilter] = useState(() => {
        let initialVal = {
            category: "all",
        }

        if (!isEmpty(urlCatData)) {
            initialVal['category'] = urlCatData
        }
        if (urlParams?.subtab && urlParams?.subtab != 'income-and-expenditure') {
            initialVal['category'] = (urlParams?.subtab).replaceAll('-', ' ')
        }

        return initialVal
    })

    // React.useEffect(() => {
    //     if (!isEmpty(urlCatData)) {
    //         setFilter({
    //             ...filter,
    //             ['category']: urlCatData,
    //         })
    //     }
    // },[urlCatData])

    console.log('urlParams', urlParams?.subtab, filter, urlCatData)


    const handleChange = (type, e) => {
        setFilter({
            ...filter,
            [type]: e.target.value,
        })
    }

    const offerData = propertyTransactions || []

    const noRecord = !isLoading && isEmpty(offerData?.tabs) // @todo: Need to check the data
    let transData = [], transTypes = [];
    if (!noRecord) {
        if (offerData?.tabs) {
            transTypes = Object.keys(offerData?.tabs);
            transTypes.map((t) => {
                let tabData = offerData.tabs[t].items;
                tabData.map((a) => {
                    transData.push({...a, category: t})
                })
            })
        }
        
    }
    console.log('transData', transData)
    // let filteredData = transData

    const filterData = transData.filter(d => {
        return (
            (!filter.category || filter.category == 'all' || d.category == filter.category)
        )
    })
    if (isLoading) {
        return <CircularProgressComponent />
        return (
            <Grid container>
                <Grid item md={8}>
                    <Stack direction="column" spacing={4}>
                        <TabContainerSkeleton noBorder>
                            <TabSkeleton />
                            <TabSkeleton />
                        </TabContainerSkeleton>
                    </Stack>
                </Grid>
                <Grid item md={4}>
                    <Stack direction="column" spacing={4}>
                        <AutoHeightSkeleton
                            width="100"
                            aspectRatio={6 / 19}
                            variant="rectangular"
                        />
                        <BodyLargeSkeleton width="50%" />
                    </Stack>
                </Grid>
            </Grid>
        )
    }

    if (noRecord) {
        return (
            <NoActions
                title="There are no Transactions"
                icon="transactionsIcon"
            />
        )
    }

    const getCategories = datas => {
        if (!datas)
            return []
        return [...new Set(datas.map(item => item.category))]
    }

    let mainPage = true;

    if (isBrowser()) {
        if (window.location.pathname.match(/income-and-expenditure/)) {
            mainPage = false
        }
    }

    console.log('urlParams ***', urlParams)

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <SectionTitle
                    {...props}
                    title={`Transactions`}
                    tabsDescription={'transactions'}
                    // tabsTitle={property?.display_address}
                    actions={[!mainPage ? '' : 'user-income-expenditure', 'contactus']}
                />
                <DocumentBox>
                    <Box className="content">
                        {(!mainPage) && (
                            <IncomeAndExpencse {...props} theme={theme} isMobile={isMobile} />
                        )}
                        {(mainPage) && (
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Button className="mobile-link-btn" onClick={() => { doRedirect('income-and-expenditure')}}>Income & Expenditure</Button>
                                    <FilterComp
                                        categories={getCategories(transData)}
                                        data={transData}
                                        handleChange={handleChange}
                                        filter={filter}
                                    />
                                    <ComponentWithPagination
                                        // {...rest}
                                        // component={ListItem}
                                        component={List}
                                        data={filterData}
                                        marginTop={25}
                                        useLoadMore={false}
                                        isMobile={isMobile}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Box>
                </DocumentBox>
            </Grid>
        </Grid>
    )
}

export default PaymentsContent
