import React from 'react';
import Skeleton from "@mui/material/Skeleton"

const TabSkeleton = () => (
    <Skeleton
        variant="text"
        width={100}
        height={30}
    />
);

export default TabSkeleton;
